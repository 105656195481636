import { render, staticRenderFns } from "./pageBanner.vue?vue&type=template&id=add20900&scoped=true&"
import script from "./pageBanner.vue?vue&type=script&lang=js&"
export * from "./pageBanner.vue?vue&type=script&lang=js&"
import style0 from "./pageBanner.vue?vue&type=style&index=0&id=add20900&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add20900",
  null
  
)

export default component.exports